import { createEnv } from '@t3-oss/env-nextjs';
import { z } from 'zod';

const trimUrl = (url: unknown) => (typeof url === 'string' ? url.trim().replace(/\/$/, '') : url);
const url = () => z.preprocess(trimUrl, z.string().url());

export const env = createEnv({
    server: {
        CMS_API_KEY: z.string(),
        CMS_PREVIEW_KEY: z.string(),
        JUCY_CACHE_API_KEY: z.string().optional(),
    },
    client: {
        NEXT_PUBLIC_ENV: z.enum(['prod', 'test', 'dev']),
        NEXT_PUBLIC_VERSION: z.string().optional(),
        NEXT_PUBLIC_RENTALS_API_BASE_URL: url(),
        NEXT_PUBLIC_CMS_API_BASE_URL: url(),
        NEXT_PUBLIC_APP_BASE_URL: url(),
        NEXT_PUBLIC_OBE_DIRECT_URL: url(),
        NEXT_PUBLIC_STAR_OBE_DIRECT_URL: url(),
        NEXT_PUBLIC_OBE_AGENT_URL: url(),
        NEXT_PUBLIC_CSS_URL: url(),
        NEXT_PUBLIC_RENTALS_API_ACCOUNT_KEY: z.string().uuid(),
        NEXT_PUBLIC_GOOGLE_MAPS_API_KEY: z.string(),
        NEXT_PUBLIC_GOOGLE_MAP_ID: z.string(),
        NEXT_PUBLIC_DD_APP_ID: z.string().optional(),
        NEXT_PUBLIC_DD_CLIENT_TOKEN: z.string().optional(),
        NEXT_PUBLIC_DD_SERVICE: z.string().optional(),
        NEXT_PUBLIC_DD_SITE: z.string().optional(),
        NEXT_PUBLIC_CJ_ENTERPRISE_ID: z.coerce.number().optional(),
        NEXT_PUBLIC_INTERCOM_API_BASE_URL: url().optional(),
        NEXT_PUBLIC_INTERCOM_APP_ID: z.string().optional(),
        NEXT_PUBLIC_TRADEDOUBLER_ORG_ID: z.coerce.number().optional(),
        NEXT_PUBLIC_CLARITY_KEY: z.string().optional(),
        NEXT_PUBLIC_DEBUG_ANALYTICS: z.coerce.boolean().optional(),
    },
    runtimeEnv: {
        CMS_PREVIEW_KEY: process.env.CMS_PREVIEW_KEY,
        CMS_API_KEY: process.env.CMS_API_KEY,
        JUCY_CACHE_API_KEY: process.env.JUCY_CACHE_API_KEY,
        NEXT_PUBLIC_ENV: process.env.NEXT_PUBLIC_ENV,
        NEXT_PUBLIC_VERSION: process.env.NEXT_PUBLIC_VERSION,
        NEXT_PUBLIC_RENTALS_API_BASE_URL: process.env.NEXT_PUBLIC_RENTALS_API_BASE_URL,
        NEXT_PUBLIC_CMS_API_BASE_URL: process.env.NEXT_PUBLIC_CMS_API_BASE_URL,
        NEXT_PUBLIC_APP_BASE_URL: process.env.NEXT_PUBLIC_APP_BASE_URL,
        NEXT_PUBLIC_OBE_DIRECT_URL: process.env.NEXT_PUBLIC_OBE_DIRECT_URL,
        NEXT_PUBLIC_STAR_OBE_DIRECT_URL: process.env.NEXT_PUBLIC_STAR_OBE_DIRECT_URL,
        NEXT_PUBLIC_OBE_AGENT_URL: process.env.NEXT_PUBLIC_OBE_AGENT_URL,
        NEXT_PUBLIC_CSS_URL: process.env.NEXT_PUBLIC_CSS_URL,
        NEXT_PUBLIC_RENTALS_API_ACCOUNT_KEY: process.env.NEXT_PUBLIC_RENTALS_API_ACCOUNT_KEY,
        NEXT_PUBLIC_GOOGLE_MAPS_API_KEY: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
        NEXT_PUBLIC_GOOGLE_MAP_ID: process.env.NEXT_PUBLIC_GOOGLE_MAP_ID,
        NEXT_PUBLIC_DD_APP_ID: process.env.NEXT_PUBLIC_DD_APP_ID,
        NEXT_PUBLIC_DD_CLIENT_TOKEN: process.env.NEXT_PUBLIC_DD_CLIENT_TOKEN,
        NEXT_PUBLIC_DD_SERVICE: process.env.NEXT_PUBLIC_DD_SERVICE,
        NEXT_PUBLIC_DD_SITE: process.env.NEXT_PUBLIC_DD_SITE,
        NEXT_PUBLIC_CJ_ENTERPRISE_ID: process.env.NEXT_PUBLIC_CJ_ENTERPRISE_ID,
        NEXT_PUBLIC_INTERCOM_API_BASE_URL: process.env.NEXT_PUBLIC_INTERCOM_API_BASE_URL,
        NEXT_PUBLIC_INTERCOM_APP_ID: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
        NEXT_PUBLIC_TRADEDOUBLER_ORG_ID: process.env.NEXT_PUBLIC_TRADEDOUBLER_ORG_ID,
        NEXT_PUBLIC_CLARITY_KEY: process.env.NEXT_PUBLIC_CLARITY_KEY,
        NEXT_PUBLIC_DEBUG_ANALYTICS: process.env.NEXT_PUBLIC_DEBUG_ANALYTICS,
    },
});
